
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'
import {Im} from '../components/PageTitle/styles'
import cowboy from '../images/cowboy.png'
const Algebrite = require('algebrite')



export default class Integral extends React.Component {
  constructor() {
    super()
    this.state = { email:'',email2:'', email3:'',latex: '', latex2: ' '}
}
  _handleSubmit = async e => {
    e.preventDefault()
    const velocity = parseFloat(this.state.email)
    const cordx = parseFloat(this.state.email2)
    const cordy = parseFloat(this.state.email3)
    const g = 9.80665
    const denom = g*cordx
    const root  = Math.sqrt(velocity**4-g*((g*cordx**2)+(2*cordy*velocity**2)))
    let resultp = (Math.atan2((velocity**2+root),denom)*180/Math.PI).toPrecision(4)
    const nanr = '\\text{La velocidad no es suficiente}'
    if(isNaN(resultp)){
        resultp = nanr
    }
    resultp = resultp + '^\\circ'
    let resultm = (Math.atan2((velocity**2-root),denom)*180/Math.PI).toPrecision(4)
        if(isNaN(resultm)){
        resultm = nanr
    }
    resultm = resultm + '^\\circ'
   
    
  this.setState( {latex:`  ${resultp} ` })
  this.setState( {latex2:`  ${resultm} ` })
  this.setState( {message: "Dispara a un ángulo de:" })
    
  }
  
handleChange = event => {
    this.setState({ email:event.target.value })
  }
  handleChange2 = event => {
    this.setState({ email2:event.target.value })
  } 
  handleChange3 = event => {
    this.setState({ email3:event.target.value })
  } 
   handleChangepsi = event => {
    this.setState({ psi:event.target.value })
  }
render() {  
    const result = (this.state.email).toString()
  return (
      
    <>
    
    <CardTitle className="text-center  mb-3">
    <div style={{ padding: '10px 30px', backgroundColor: 'orange', margin: '1em' }}>
</div>


<a><h1> Equipo 3 </h1></a>

<div style={{ padding: '10px 30px', backgroundColor: 'orange' , margin: '1em' }}>
</div>

    <a><h3> Calculadora tiro parabólico </h3></a>
  </CardTitle>
  <img  style={{ position: 'relative', maxWidth: '100%'   }} src={cowboy} alt="hello there" />

  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
            <a>Ingrese velocidad inicial, m/s </a>
        <Input
         
          label="Email"
          autoComplete="email"
          placeholder="velocidad m/s"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <a>Ingrese coordenada x, m</a>
         <Input
         
         label="Email"
         autoComplete="email"
         placeholder="coordenada x, m"
         variant="outlined"
         value={this.state.email2}
         onChange={this.handleChange2}
       />
       <a>Ingrese coordenada y, m</a>
       <Input
         
         label="Email"
         autoComplete="email"
         placeholder="coordenada y, m"
         variant="outlined"
         value={this.state.email3}
         onChange={this.handleChange3}
       />
 
        <br />
        
        <BlockMath math={ this.state.email != ''  || this.state.email2 != '' || this.state.email3 != '' ? 
         `\\theta=\\arctan \\left(\\frac{${this.state.email}^2 \\pm \\sqrt{${this.state.email}^4-g\\left(g \\times ${this.state.email2}^2+2 \\times ${this.state.email3} \\times ${this.state.email}^2\\right)}}{${this.state.email2}g}\\right)`
          : `\\theta=\\arctan \\left(\\frac{v^2 \\pm \\sqrt{v^4-g\\left(g \\times x^2+2 \\times y \\times v^2\\right)}}{xg}\\right)`}
         errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />
 <br />


          <br />
        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
       < button  className="btn btn-outline-success text-uppercase">
         Calcular
          </button>
          </motion.div>
          <br />
          <br />
          < h3> {this.state.message} </h3>
          <br />
          <br />
          <BlockMath math={`\\theta_+ = ${this.state.latex}`}
          
          />
            <BlockMath math ={`\\theta_- = ${this.state.latex2}`}
          
          />
        </FormGroup>
      </Form>
      </>
    )
  }
}
