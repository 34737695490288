import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GlobalStyle } from '../util/styles'
import {Im} from '../components/PageTitle/styles'
import galaxy from '../images/galaxy.png'
import {PPost} from '../components/PostExcerpt/styles'
import 'katex/dist/katex.min.css'
import Angle from '../components/proyectos'
import {Ptd, Ptd2} from '../components/PageTitle/styles'

const ProyectFisica = () => (
  <>

    <PPost style={{ minHeight: '100%'}}>
    <SEO title="Equipo 3, Proyecto 3" keywords={[`Calculus`, `Matrix Calculator`, `Inverse of a matrix`]} />
  <div class="matrix">

    <Angle/>
    </div>
    </PPost>

  </>
  

)



export default ProyectFisica